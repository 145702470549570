var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "대관업무 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "LBLSAVEALL",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && !_vm.isOld,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-select", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            codeGroupCd: "GOV_SCHEDULE_TYPE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "govScheduleTypeCd",
                            label: "대관업무 구분",
                          },
                          model: {
                            value: _vm.data.govScheduleTypeCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "govScheduleTypeCd", $$v)
                            },
                            expression: "data.govScheduleTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "대관업무명",
                            name: "govScheduleName",
                          },
                          model: {
                            value: _vm.data.govScheduleName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "govScheduleName", $$v)
                            },
                            expression: "data.govScheduleName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "실시 주기",
                            name: "govScheduleCycleTerm",
                          },
                          model: {
                            value: _vm.data.govScheduleCycleTerm,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "govScheduleCycleTerm", $$v)
                            },
                            expression: "data.govScheduleCycleTerm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            comboItems: _vm.cycleItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "govScheduleCycleCd",
                            label: "실시 주기(단위)",
                          },
                          model: {
                            value: _vm.data.govScheduleCycleCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "govScheduleCycleCd", $$v)
                            },
                            expression: "data.govScheduleCycleCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "LBLUSEFLAG",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.data.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "useFlag", $$v)
                            },
                            expression: "data.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "업무내용",
                            name: "remarks",
                            rows: 3,
                          },
                          model: {
                            value: _vm.data.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "remarks", $$v)
                            },
                            expression: "data.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
            [
              _c(
                "c-table",
                {
                  ref: "grid",
                  attrs: {
                    title: "대관업무 담당자",
                    tableId: "grid",
                    columns: _vm.grid.columns,
                    data: _vm.data.users,
                    isFullScreen: false,
                    columnSetting: false,
                    editable: _vm.editable,
                    filtering: false,
                    gridHeight: "352px",
                    selection: "multiple",
                    rowKey: "mngUserId",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "LBLADD", icon: "add" },
                                on: { btnClicked: _vm.addrow },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "LBLEXCEPT", icon: "remove" },
                                on: { btnClicked: _vm.removeRow },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "검사관련 정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "INSP_TYPE",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "inspType",
                            label: "검사종류",
                          },
                          model: {
                            value: _vm.data.inspType,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "inspType", $$v)
                            },
                            expression: "data.inspType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "RELATED_LAWS_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "relatedLawsCd",
                            label: "관련법규",
                          },
                          model: {
                            value: _vm.data.relatedLawsCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "relatedLawsCd", $$v)
                            },
                            expression: "data.relatedLawsCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "대상시설",
                            name: "inspTarget",
                          },
                          model: {
                            value: _vm.data.inspTarget,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "inspTarget", $$v)
                            },
                            expression: "data.inspTarget",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "계약서관련 정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "CONT_TYPE",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "contType",
                            label: "계약구분",
                          },
                          model: {
                            value: _vm.data.contType,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "contType", $$v)
                            },
                            expression: "data.contType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "CONT_STATUS",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "contStatus",
                            label: "계약상태",
                          },
                          model: {
                            value: _vm.data.contStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "contStatus", $$v)
                            },
                            expression: "data.contStatus",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "계약업체",
                            name: "contVendor",
                          },
                          model: {
                            value: _vm.data.contVendor,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "contVendor", $$v)
                            },
                            expression: "data.contVendor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "대관업무 관련 파일" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-upload", {
                          attrs: {
                            attachInfo: _vm.attachInfo,
                            editable: _vm.editable,
                            label: "대관업무 관련 파일 첨부파일",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }